import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ItemFav } from './types';

const ITEMS_KEY = 'oJED_Fav';
const LNG_KEY = 'oJED_Lang';

@Injectable({ providedIn: 'root' })
export class StorageService {

  constructor(private storage: Storage) { }
  
  getFavs(): Promise<ItemFav[]> {
    return this.storage.get(ITEMS_KEY);
  }

  addItem (item: ItemFav): Promise<any> {
    return this.storage.get(ITEMS_KEY).then((items: ItemFav[]) => {
      // ensuring no dublicates
      if (items) { if (!items.find(a => a.id === item.id)) {items.push(item); return this.storage.set(ITEMS_KEY, items); } return; }      
      else { return this.storage.set(ITEMS_KEY, [item]); }
    });
  }

  deleteItem (id: string): Promise<ItemFav[]> {
    return this.storage.get(ITEMS_KEY).then((items: ItemFav[]) => {
      if (!items || items.length === 0) return null;

      let toKeep: ItemFav[] = [];
      for (let i of items) { if (i.id !== id) toKeep.push(i); }     
      return this.storage.set(ITEMS_KEY, toKeep); 
    });
  }

  getLang() {
    this.storage.get(LNG_KEY).then(val => {
      if(val){ this.setLang(val); return val; }
    });
  }

  setLang(lng) { this.storage.set(LNG_KEY, lng); }
}

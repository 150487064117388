import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class LanguageService {
  selected = '';

  constructor(
    private translate: TranslateService, 
    private storageService: StorageService
    ) { }

  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);

    this.storageService.getLang();
  }

  getLanguages() {
    return [
      { text: 'Arabic', value: 'ar' },
      { text: 'English', value: 'en' }    
    ];
  }

  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    this.storageService.setLang(lng);
  }
}

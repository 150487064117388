import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'arts', pathMatch: 'full' },  
  { path: 'arts',loadChildren: './pages/arts/arts.module#ArtsPageModule'},
  { path: 'detail',loadChildren: './pages/art-detail/art-detail.module#ArtDetailPageModule'},
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LanguageService } from './services/language.service';
import { timer } from 'rxjs/internal/observable/timer';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  showSplash = true;
  //showSplash = false;

  constructor(
    private platform: Platform,
    private languageService: LanguageService
  ) {
    this.initializeApp();
  }

  /**
   * !! splash
   * exit from app on back-button
   */
  initializeApp() {
    this.platform.ready().then(() => {
      timer(3000).subscribe(() => { this.showSplash = false;});

      this.languageService.setLanguage('ar');

      this.platform.backButton.subscribe(() => { 
        navigator['app'].exitApp();
      });
    });
  }

  ngOnDestroy(){ 
    navigator['app'].exitApp();
  }
}
